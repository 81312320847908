.awemenu-nav:before, .awemenu-nav:after {
  display: table;
  content: '';
  clear: both;
}

.awe-fr {
  float: right;
}

.awemenu-mobile .awemenu {
  overflow-x: hidden;
  overflow-y: auto;
}
.awemenu-mobile.awemenu-mobile-standard .awemenu, .awemenu-mobile.awemenu-mobile-top .awemenu {
  overflow: visible;
}

.awemenu-sticky {
  z-index: 999;
}

.awemenu-nav {
  position: relative;
  z-index: 999999;
}
.awemenu-nav * {
  box-sizing: border-box;
}
.awemenu-nav:after, .awemenu-nav:before {
  content: '';
  display: block;
  clear: both;
}

.awemenu-dropshadow .awemenu-submenu {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
}

.awemenu-active .awemenu {
  display: block;
}

.awemenu-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.awemenu-container:after, .awemenu-container:before {
  content: '';
  clear: both;
  display: block;
}

.awemenu {
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
}
.awemenu:after, .awemenu:before {
  content: '';
  clear: both;
  display: block;
}
.awemenu .awemenu-item > a {
  display: block;
}
.awemenu .awemenu-item > a i {
  vertical-align: middle;
}

.awemenu-submenu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: none;
}

.awemenu-active > .awemenu-submenu {
  z-index: 999;
  display: block;
}

.awemenu-icon .icon-top, .awemenu-icon .icon-bottom {
  display: block;
}

.awemenu-has-logo .awemenu-logo {
  float: left;
}
.awemenu-has-logo .awemenu {
  float: right;
  position: static;
}

.awemenu-bars {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  display: none;
}

.awemenu-standard .awemenu-arrow, .awemenu-top .awemenu-arrow {
  display: inline-block;
  text-align: center;
  margin-left: 10px;
}
.awemenu-standard .awemenu > .awemenu-item, .awemenu-top .awemenu > .awemenu-item {
  float: left;
}
.awemenu-standard .awemenu > .awemenu-item.awemenu-item-right, .awemenu-top .awemenu > .awemenu-item.awemenu-item-right {
  float: right;
}
.awemenu-standard .awemenu > .awemenu-item > a .awemenu-arrow, .awemenu-top .awemenu > .awemenu-item > a .awemenu-arrow {
  margin-left: 5px;
}
.awemenu-standard .awemenu > .awemenu-item .awemenu-submenu, .awemenu-top .awemenu > .awemenu-item .awemenu-submenu {
  position: absolute;
}
.awemenu-standard .awemenu > .awemenu-item .awemenu-submenu .awemenu-item, .awemenu-top .awemenu > .awemenu-item .awemenu-submenu .awemenu-item {
  position: relative;
}
.awemenu-standard .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown, .awemenu-top .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown {
  width: 250px;
}
.awemenu-standard .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown .awemenu-item .awemenu-submenu, .awemenu-top .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown .awemenu-item .awemenu-submenu {
  width: 100%;
  left: 100%;
  top: 0;
}
.awemenu-standard .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown .awemenu-item > a .awemenu-arrow, .awemenu-top .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown .awemenu-item > a .awemenu-arrow {
  float: right;
}
.awemenu-standard .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown .awemenu-item.awemenu-invert > a .awemenu-arrow, .awemenu-top .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown .awemenu-item.awemenu-invert > a .awemenu-arrow {
  float: left;
  margin-right: 10px;
  margin-left: 0;
}
.awemenu-standard .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown .awemenu-item.awemenu-invert > .awemenu-submenu, .awemenu-top .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown .awemenu-item.awemenu-invert > .awemenu-submenu {
  left: auto;
  right: 100%;
}
.awemenu-standard .awemenu > .awemenu-item .awemenu-submenu.awemenu-megamenu, .awemenu-top .awemenu > .awemenu-item .awemenu-submenu.awemenu-megamenu {
  width: 500px;
}

.awemenu-sticky {
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
}

.awemenu-top {
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  position: fixed;
}

.awemenu-left, .awemenu-outleft {
  float: left;
}
.awemenu-left.awemenu-nav.awemenu-has-logo .awemenu, .awemenu-nav.awemenu-has-logo.awemenu-outleft .awemenu {
  float: left;
}
.awemenu-left .awemenu-arrow, .awemenu-outleft .awemenu-arrow {
  float: right;
}
.awemenu-left .awemenu, .awemenu-outleft .awemenu {
  width: 250px;
  clear: both;
}
.awemenu-left .awemenu .awemenu-item, .awemenu-outleft .awemenu .awemenu-item {
  display: block;
  position: relative;
}
.awemenu-left .awemenu .awemenu-item .awemenu-submenu, .awemenu-outleft .awemenu .awemenu-item .awemenu-submenu {
  left: 100%;
  top: 0;
  width: 100%;
  position: absolute;
}
.awemenu-left .awemenu .awemenu-item .awemenu-submenu.awemenu-dropdown, .awemenu-outleft .awemenu .awemenu-item .awemenu-submenu.awemenu-dropdown {
  width: 250px;
}
.awemenu-left .awemenu .awemenu-item .awemenu-submenu.awemenu-megamenu, .awemenu-outleft .awemenu .awemenu-item .awemenu-submenu.awemenu-megamenu {
  width: 500px;
}
.awemenu-left .awemenu .awemenu-invert > a .awemenu-arrow, .awemenu-outleft .awemenu .awemenu-invert > a .awemenu-arrow {
  float: left;
  margin-right: 10px;
  margin-left: 0;
}
.awemenu-left .awemenu .awemenu-invert > .awemenu-submenu, .awemenu-outleft .awemenu .awemenu-invert > .awemenu-submenu {
  left: auto;
  right: 100%;
}

.awemenu-left, .awemenu-outleft,
.awemenu-right,
.awemenu-outright {
  display: inline-block;
}

.awemenu-right, .awemenu-outright {
  float: right;
}
.awemenu-right .awemenu-arrow, .awemenu-outright .awemenu-arrow {
  float: left;
  margin-right: 10px;
}
.awemenu-right .awemenu, .awemenu-outright .awemenu {
  width: 250px;
  clear: both;
}
.awemenu-right .awemenu .awemenu-item, .awemenu-outright .awemenu .awemenu-item {
  display: block;
  position: relative;
}
.awemenu-right .awemenu .awemenu-item .awemenu-submenu, .awemenu-outright .awemenu .awemenu-item .awemenu-submenu {
  right: 100%;
  top: 0;
  width: 100%;
  left: auto;
  position: absolute;
}
.awemenu-right .awemenu .awemenu-item .awemenu-submenu.awemenu-dropdown, .awemenu-outright .awemenu .awemenu-item .awemenu-submenu.awemenu-dropdown {
  width: 250px;
}
.awemenu-right .awemenu .awemenu-item .awemenu-submenu.awemenu-megamenu, .awemenu-outright .awemenu .awemenu-item .awemenu-submenu.awemenu-megamenu {
  width: 500px;
}
.awemenu-right .awemenu .awemenu-invert > a .awemenu-arrow, .awemenu-outright .awemenu .awemenu-invert > a .awemenu-arrow {
  float: right;
  margin-left: 10px;
  margin-right: 0;
}
.awemenu-right .awemenu .awemenu-invert > .awemenu-submenu, .awemenu-outright .awemenu .awemenu-invert > .awemenu-submenu {
  right: auto;
  left: 100%;
}
.awemenu-right .awemenu > .awemenu-item .awemenu-arrow, .awemenu-outright .awemenu > .awemenu-item .awemenu-arrow {
  margin-right: 10px;
}

.awemenu-outleft {
  display: block;
  float: none;
}
.awemenu-outleft.awemenu-nav .awemenu-bars {
  display: block;
}
.awemenu-outleft.awemenu-nav.awemenu-has-logo .awemenu {
  float: none;
  position: fixed;
}
.awemenu-outleft.awemenu-nav.awemenu-has-logo .awemenu-bars {
  float: right;
}
.awemenu-outleft .awemenu {
  top: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.awemenu-outleft.awemenu-active .awemenu {
  z-index: 999;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.awemenu-outleft.awemenu-sticky {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

.awemenu-outright {
  display: block;
  float: none;
}
.awemenu-outright.awemenu-nav.awemenu-has-logo .awemenu {
  float: none;
  position: fixed;
}
.awemenu-outright.awemenu-nav.awemenu-has-logo .awemenu-bars {
  float: right;
}
.awemenu-outright .awemenu {
  position: fixed;
  float: none;
  top: 0;
  bottom: 0;
  right: 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.awemenu-outright.awemenu-nav .awemenu-bars {
  display: block;
}
.awemenu-outright.awemenu-active .awemenu {
  z-index: 999;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.awemenu-outright.awemenu-sticky {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

.awemenu-bottom {
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  position: fixed;
}
.awemenu-bottom .awemenu > .awemenu-item {
  float: left;
}
.awemenu-bottom .awemenu > .awemenu-item > a .awemenu-arrow {
  margin-left: 5px;
}
.awemenu-bottom .awemenu > .awemenu-item.awemenu-item-right {
  float: right;
}
.awemenu-bottom .awemenu > .awemenu-item .awemenu-submenu {
  position: absolute;
  bottom: 100%;
}
.awemenu-bottom .awemenu > .awemenu-item .awemenu-submenu .awemenu-item {
  position: relative;
}
.awemenu-bottom .awemenu > .awemenu-item .awemenu-submenu .awemenu-item .awemenu-submenu {
  bottom: 0;
  width: 100%;
}
.awemenu-bottom .awemenu > .awemenu-item .awemenu-submenu .awemenu-item.awemenu-vertical-invert > .awemenu-submenu {
  bottom: auto;
  top: 0;
}
.awemenu-bottom .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown {
  width: 250px;
}
.awemenu-bottom .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown .awemenu-item > a .awemenu-arrow {
  float: right;
}
.awemenu-bottom .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown .awemenu-item .awemenu-submenu {
  width: 100%;
  left: 100%;
}
.awemenu-bottom .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown .awemenu-item.awemenu-invert > a .awemenu-arrow {
  float: left;
}
.awemenu-bottom .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown .awemenu-item.awemenu-invert > .awemenu-submenu {
  left: auto;
  right: 100%;
}
.awemenu-bottom .awemenu > .awemenu-item .awemenu-submenu.awemenu-megamenu {
  width: 500px;
}

.awemenu-mobile .awemenu-item > a {
  position: relative;
}
.awemenu-mobile .awemenu-item > a > .awemenu-arrow {
  position: absolute;
  width: 55px;
  height: 100%;
  top: 0;
  
  right: 0;
  
  
  text-align: center;
}
.awemenu-mobile .awemenu-item > a > .awemenu-arrow:before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.awemenu-mobile-standard.awemenu-has-logo .awemenu, .awemenu-has-logo.awemenu-mobile-top .awemenu {
  float: none;
  clear: both;
  position: absolute;
  width: 100%;
}
.awemenu-mobile-standard.awemenu-has-logo .awemenu-bars, .awemenu-has-logo.awemenu-mobile-top .awemenu-bars {
  float: right;
}
.awemenu-mobile-standard .awemenu, .awemenu-mobile-top .awemenu {
  left: 0;
  right: 0;
  top: 100%;
  z-index: 99;
  position: absolute;
  display: none;
}
.awemenu-mobile-standard .awemenu .awemenu-item > a .awemenu-arrow, .awemenu-mobile-top .awemenu .awemenu-item > a .awemenu-arrow {
  float: right;
}
.awemenu-mobile-standard.awemenu-nav .awemenu-bars, .awemenu-nav.awemenu-mobile-top .awemenu-bars {
  display: block;
}
.awemenu-mobile-standard.awemenu-sticky, .awemenu-sticky.awemenu-mobile-top {
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
}
.awemenu-mobile-standard.awemenu-sticky .awemenu, .awemenu-sticky.awemenu-mobile-top .awemenu {
  overflow-y: auto;
}

.awemenu-mobile-top {
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
}

.awemenu-mobile-bottom {
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
}
.awemenu-mobile-bottom.awemenu-has-logo .awemenu-bars {
  float: right;
}
.awemenu-mobile-bottom.awemenu-has-logo .awemenu {
  float: none;
  position: absolute;
}
.awemenu-mobile-bottom.awemenu-nav .awemenu-bars {
  display: block;
}
.awemenu-mobile-bottom .awemenu {
  overflow-y: auto;
  left: 0;
  right: 0;
  bottom: 100%;
  display: none;
  position: absolute;
}
.awemenu-mobile-bottom .awemenu .awemenu-item > a .awemenu-arrow {
  float: right;
}

.awemenu-mobile-outleft.awemenu-has-logo .awemenu-bars, .awemenu-has-logo.awemenu-mobile-outright .awemenu-bars {
  float: right;
}
.awemenu-mobile-outleft.awemenu-has-logo .awemenu, .awemenu-has-logo.awemenu-mobile-outright .awemenu {
  float: none;
  position: fixed;
}
.awemenu-mobile-outleft .awemenu, .awemenu-mobile-outright .awemenu {
  top: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: fixed;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.awemenu-mobile-outleft .awemenu .awemenu-item > a .awemenu-arrow, .awemenu-mobile-outright .awemenu .awemenu-item > a .awemenu-arrow {
  float: right;
}
.awemenu-mobile-outleft.awemenu-active .awemenu, .awemenu-active.awemenu-mobile-outright .awemenu {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.awemenu-mobile-outleft.awemenu-nav .awemenu-bars, .awemenu-nav.awemenu-mobile-outright .awemenu-bars {
  display: block;
  z-index: 999999;
}
.awemenu-mobile-outleft.awemenu-sticky, .awemenu-sticky.awemenu-mobile-outright {
  top: 0;
  right: 0;
  left: 0;
  position: fixed;
}

.awemenu-mobile-outright.awemenu-has-logo .awemenu-bars {
  float: right;
}
.awemenu-mobile-outright .awemenu {
  left: auto;
  right: 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.awemenu-mobile-outright.awemenu-active .awemenu {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.awemenu-mobile-outright.awemenu-sticky {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

/* Full screen */
.awemenu-fullscreen.awemenu-nav .awemenu-bars {
  display: block;
}
.awemenu-fullscreen .awemenu-submenu {
  width: 100% !important;
  left: auto !important;
}

.awemenu-fullscreen-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  visibility: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.awemenu-fullscreen-wrapper .awemenu {
  position: static;
}
.awemenu-fullscreen-wrapper .awemenu-item > a {
  text-align: center;
}

.awemenu-fullscreen-table {
  display: table;
  width: 100%;
  height: 100%;
}

.awemenu-fullscreen-cell {
  display: table-cell;
  vertical-align: middle;
}

.awemenu-active .awemenu-fullscreen-wrapper {
  visibility: visible;
}

.awemenu-arrow-decor {
  position: absolute;
  top: 0;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 6px;
  border-color: #000;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  -webkit-transform: translate(0, -100%);
  transform: translate(0, -100%);
}

.awemenu-close {
  cursor: pointer;
  color: #888;
}
.awemenu-close .amm-clear {
  color: #fff;
  font-size: 24px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.awemenu-stickyup {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  visibility: hidden;
  -webkit-transform: translate(0, -100%);
  transform: translate(0, -100%);
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
}
.awemenu-stickyup.awemenu-scrollup {
  visibility: visible;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.awemenu-right .awemenu-arrow, .awemenu-outright .awemenu-arrow,
.awemenu-outright .awemenu-arrow,
.awemenu-left .awemenu-arrow,
.awemenu-outleft .awemenu-arrow,
.awemenu-outleft .awemenu-arrow {
  margin-top: 4px;
}

/* Right to left */
.awemenu-rtl.awemenu-standard .awemenu > .awemenu-item, .awemenu-rtl.awemenu-top .awemenu > .awemenu-item, .awemenu-rtl.awemenu-top .awemenu > .awemenu-item, .awemenu-rtl.awemenu-bottom .awemenu > .awemenu-item {
  float: right;
}
.awemenu-rtl.awemenu-standard .awemenu > .awemenu-item > a .awemenu-arrow, .awemenu-rtl.awemenu-top .awemenu > .awemenu-item > a .awemenu-arrow, .awemenu-rtl.awemenu-top .awemenu > .awemenu-item > a .awemenu-arrow, .awemenu-rtl.awemenu-bottom .awemenu > .awemenu-item > a .awemenu-arrow {
  margin-left: 0;
  margin-right: 5px;
}
.awemenu-rtl.awemenu-standard .awemenu > .awemenu-item.awemenu-item-right, .awemenu-rtl.awemenu-top .awemenu > .awemenu-item.awemenu-item-right, .awemenu-rtl.awemenu-top .awemenu > .awemenu-item.awemenu-item-right, .awemenu-rtl.awemenu-bottom .awemenu > .awemenu-item.awemenu-item-right {
  float: left;
}
.awemenu-rtl.awemenu-standard .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown .awemenu-item > a .awemenu-arrow, .awemenu-rtl.awemenu-top .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown .awemenu-item > a .awemenu-arrow, .awemenu-rtl.awemenu-top .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown .awemenu-item > a .awemenu-arrow, .awemenu-rtl.awemenu-bottom .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown .awemenu-item > a .awemenu-arrow {
  float: left;
}
.awemenu-rtl.awemenu-standard .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown .awemenu-item.awemenu-invert > a .awemenu-arrow, .awemenu-rtl.awemenu-top .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown .awemenu-item.awemenu-invert > a .awemenu-arrow, .awemenu-rtl.awemenu-top .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown .awemenu-item.awemenu-invert > a .awemenu-arrow, .awemenu-rtl.awemenu-bottom .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown .awemenu-item.awemenu-invert > a .awemenu-arrow {
  float: right;
  margin-right: 0;
  margin-left: 10px;
}
.awemenu-rtl.awemenu-standard .awemenu-arrow, .awemenu-rtl.awemenu-top .awemenu-arrow, .awemenu-rtl.awemenu-top .awemenu-arrow, .awemenu-rtl.awemenu-bottom .awemenu-arrow {
  margin-left: 0;
  margin-right: 10px;
}
.awemenu-rtl.awemenu-standard .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown .awemenu-item .awemenu-submenu, .awemenu-rtl.awemenu-top .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown .awemenu-item .awemenu-submenu, .awemenu-rtl.awemenu-top .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown .awemenu-item .awemenu-submenu, .awemenu-rtl.awemenu-bottom .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown .awemenu-item .awemenu-submenu {
  left: auto;
  right: 100%;
}
.awemenu-rtl.awemenu-standard .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown .awemenu-item.awemenu-invert > .awemenu-submenu, .awemenu-rtl.awemenu-top .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown .awemenu-item.awemenu-invert > .awemenu-submenu, .awemenu-rtl.awemenu-top .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown .awemenu-item.awemenu-invert > .awemenu-submenu, .awemenu-rtl.awemenu-bottom .awemenu > .awemenu-item .awemenu-submenu.awemenu-dropdown .awemenu-item.awemenu-invert > .awemenu-submenu {
  right: auto;
  left: 100%;
}
.awemenu-rtl.awemenu-left, .awemenu-rtl.awemenu-outleft {
  float: left;
}
.awemenu-rtl.awemenu-outleft {
  float: none;
}
.awemenu-rtl.awemenu-mobile-standard .awemenu .awemenu-item > a .awemenu-arrow, .awemenu-rtl.awemenu-mobile-top .awemenu .awemenu-item > a .awemenu-arrow, .awemenu-rtl.awemenu-mobile-top .awemenu .awemenu-item > a .awemenu-arrow {
  float: left;
}
.awemenu-rtl.awemenu-mobile .awemenu-item > a > .awemenu-arrow {
  left: 0;
  right: auto;
}

@font-face {
  font-family: 'awemmenu';
  src: url("awemmenu.eot?pw77j9");
  src: url("awemmenu.eot?pw77j9#iefix") format("embedded-opentype"), url("awemmenu.ttf?pw77j9") format("truetype"), url("awemmenu.woff?pw77j9") format("woff"), url("awemmenu.svg?pw77j9#awemmenu") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^="amm-"], [class*=" amm-"] {
  display: inline-block;
  font-family: 'awemmenu';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.amm-clear:before {
  content: "\e070";
}

.amm-bar:before {
  content: "\e20e";
}

.amm-down:before {
  content: "\e901";
}

.amm-left:before {
  content: "\e902";
}

.amm-right:before {
  content: "\e903";
}

.amm-up:before {
  content: "\e904";
}
